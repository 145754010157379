import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Grid, Typography } from "@mui/material";
import {
  faChartPie,
  faChartSimple,
  faFileContract,
  faCubes,
  faTrees,
  faBarsProgress,
  faChartNetwork,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import NewLayout from "../../components/NewLayout";
import Hero from "../../components/Hero";
import Container from "../../components/Container";
import ArrowButton from "../../components/ArrowButton";
import LatestUpdates from "../../components/LatestUpdates";
import backgroundImage from "../../images/headers/distributors.png";
import toolsImage from "../../images/distributors/fund-tools.png";
import dataImage from "../../images/distributors/data-analytics.png";
import nextportfolio4Image from "../../images/distributors/nextportfolio4.png";
import esgImage from "../../images/distributors/esg.png";
import regulatoryImage from "../../images/distributors/regulatory.png";
import blockchainImage from "../../images/distributors/blockchain.png";
import investmentImage from "../../images/distributors/investment.png";
import nextportfolioIcon from "../../images/distributors/nextportfolio-icon.png";
import InterestedItems from "../../components/InterestedItems";
import ModalRequestDemo from "../../components/ModalRequestDemo";
import { colors } from "../../theme";
import { headers, restHeaders, additionalLinks } from "../../utils/navbar";
import RotatedGridServices from "../../components/RotatedGridServices";
import QuarterlyFigures from "../../components/QuarterlyFigures";

const benefits = () => [
  {
    title: <Trans>Scale</Trans>,
    description: (
      <Trans>
        Allfunds offer the largest fund offering with global distribution
        agreements.
      </Trans>
    ),
  },
  {
    title: <Trans>Tailored Pricing</Trans>,
    description: (
      <Trans>
        Distributors enjoy attractive pricing options that translate to cost
        savings.
      </Trans>
    ),
  },
  {
    title: <Trans>Global Presence, Local Approach</Trans>,
    description: (
      <Trans>
        We provide support in all our operating markets from 17 offices to
        ensure both global coverage and local assistance.
      </Trans>
    ),
  },
  {
    title: <Trans>One-Stop Shop</Trans>,
    description: (
      <Trans>
        The first one-stop shop of its kind, Allfunds continues to be the market
        leader with the broadest selection of services in the industry.
      </Trans>
    ),
  },
];

const digitalTools = () => [
  {
    title: <Trans>Fund Solutions</Trans>,
    description: (
      <Trans>
        Information and reporting tools to navigate the broadest range of funds
        and ETFs in the market, including alternative investments.
      </Trans>
    ),
    color: colors.red,
    icon: faSearch,
    image: toolsImage,
    content: (
      <ul>
        <li>
          <Trans>
            Screen and compare up to 100 ISINs with more than 20 criteria to
            filter by
          </Trans>
        </li>
        <li>
          <Trans>Fund factsheets</Trans>
        </li>
        <li>
          <Trans>Watchlists tool</Trans>
        </li>
        <li>
          <Trans>Customised outputs</Trans>
        </li>
      </ul>
    ),
    modalPosition: { left: 0 },
    circlePosition: { top: "-1rem", right: "1rem" },
    linkPosition: {
      // transform: "rotate(-90deg)",
      transform: "rotate(-45deg) scaleY(-1) translate(-60px, 80px)",
      before: "rotate(45deg) translate(-85%, 50px)",
    },
    gridColumn: "1/2",
    gridRow: "1/2",
  },
  {
    title: <Trans>Allfunds Data Analytics</Trans>,
    description: <Trans>Advanced data and market flow analytics.</Trans>,
    color: colors.blueLight,
    icon: faChartSimple,
    image: dataImage,
    content: (
      <ul>
        <li>
          <Trans>Advanced reporting</Trans>
        </li>
      </ul>
    ),
    modalPosition: { right: "-5rem", top: "-2rem" },
    circlePosition: { left: "-1rem", top: "15%" },
    linkPosition: {
      transform: "rotate(-90deg) translate(125px, -10px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "2/3",
    gridRow: "1/2",
  },
  {
    title: <Trans>Nextportfolio4</Trans>,
    description: (
      <Trans>
        Empower your financial advisors and portfolio managers with tools to streamline processes, personalize strategies, and drive commercial activity.
      </Trans>
    ),
    color: colors.tourquesa,
    iconImage: nextportfolioIcon,
    image: nextportfolio4Image,
    content: (
      <ul>
        <li>
          <Trans><b>Automate</b> work overload</Trans>
        </li>
        <li>
          <Trans>Scale without complexity</Trans>
        </li>
        <li>
          <Trans>Gain real-time portfolio visibility</Trans>
        </li>
        <li>
          <Trans>Customize reporting</Trans>
        </li>
      </ul>
    ),
    modalPosition: { right: "-7rem", top: "24rem" },
    circlePosition: { top: "-1rem", left: "20%" },
    linkPosition: {
      transform: "rotate(0deg) translate(125px, -70px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "3/4",
    gridRow: "1/2",
    path: "/products/nextportfolio4",
  },
  {
    title: <Trans>ESG Solutions</Trans>,
    description: (
      <Trans>
        An advanced suite of solutions to tackle the ESG challenge and meet the
        full cycle of financial entities business needs.
      </Trans>
    ),
    color: colors.green,
    icon: faTrees,
    image: esgImage,
    content: (
      <ul>
        <li>
          <Trans>ESG scoring</Trans>
        </li>
        <li>
          <Trans>ESG reporting</Trans>
        </li>
        <li>
          <Trans>ESG portfolio advisory</Trans>
        </li>
        <li>
          <Trans>ESG audit and certification</Trans>
        </li>
        <li>
          <Trans>EET analyzer</Trans>
        </li>
      </ul>
    ),
    modalPosition: { left: 0, top: "4rem" },
    circlePosition: { right: "-1rem", bottom: "8%" },
    linkPosition: {
      transform: "rotate(-90deg)",
      before: "rotate(45deg) translate(-85%, 50px)",
    },
    gridColumn: "2/3",
    gridRow: "2/3",
  },
  {
    title: <Trans>Regulatory Solutions</Trans>,
    description: (
      <Trans>
        Regulatory data hub and MifidII Solution Reporting, due diligence
        portal, share class selection, solvency information and corporate
        actions management.
      </Trans>
    ),
    color: colors.blue,
    icon: faFileContract,
    image: regulatoryImage,
    content: (
      <ul>
        <li>
          <Trans>Share class selection tool</Trans>
        </li>
        <li>
          <Trans>Corporate actions</Trans>
        </li>
        <li>
          <Trans>Fund documentation</Trans>
        </li>
        <li>
          <Trans>Regulatory reporting</Trans>
        </li>
      </ul>
    ),
    modalPosition: { right: "-5rem", top: "32.7rem" },
    circlePosition: { left: "15%", top: "-1rem" },
    linkPosition: {
      transform: "rotate(-45deg) translateX(130px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "3/4",
    gridRow: "2/3",
  },
  {
    title: <Trans>Allfunds Blockchain</Trans>,
    description: (
      <Trans>
        Proprietary software and network for increased operational efficiency
        and transparency.
      </Trans>
    ),
    color: colors.purple,
    icon: faCubes,
    image: blockchainImage,
    content: (
      <ul>
        <li>
          <Trans>
            Next generation investors will require a unique investment
            experience. Allfunds Blockchain helps its partners to be ready for
            the true digital era.
          </Trans>
        </li>
      </ul>
    ),
    path: "/distributors/blockchain",
    modalPosition: { left: 0, top: "10rem" },
    circlePosition: { right: "-1rem", bottom: "16%" },
    linkPosition: {
      height: "2px",
      transform: "translate(-100%, -70px)",
      before: "rotate(135deg) translate(85%, 50px)",
    },
    gridColumn: "3/4",
    gridRow: "3/4",
  },
  {
    title: <Trans>ManCo & Investment Solutions</Trans>,
    description: (
      <Trans>
        An area that provides investment expertise to support banks, wealth
        managers and institutional investors in their product development.
      </Trans>
    ),
    color: colors.orange,
    icon: faChartPie,
    image: investmentImage,
    content: (
      <ul>
        <li>
          <Trans>Due diligence and Fund Monitoring</Trans>
        </li>
        <li>
          <Trans>Model portfolio solutions</Trans>
        </li>
        <li>
          <Trans>B2B Sub-Advisory platform with multi-boutiques offering</Trans>
        </li>
        <li>
          <Trans>Fund wrapping capabilities (DPM wrappers , FoF…etc.)</Trans>
        </li>
      </ul>
    ),
    path: "/distributors/investment",
    modalPosition: { right: "-8rem", top: "32.7rem" },
    circlePosition: { left: "-1rem", top: "8%" },
    linkPosition: {
      transform: "rotate(-90deg) translateX(180px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "4/5",
    gridRow: "3/4",
  },
  {
    title: <Trans>Alternatives</Trans>,
    color: colors.red,
    icon: faBarsProgress,
    image: blockchainImage,
    content: (
      <ul>
        <li>
          <Trans>
            Access Evergreen and illiquid funds as simply as UCITS funds
          </Trans>
        </li>
        <li>
          <Trans>
            All types of alternative structures available: ELTIFs, UCI Part II,
            FCRs, RAIFs, etc.
          </Trans>
        </li>
        <li>
          <Trans>
            Provide your clients with the best strategies from top-tier GPs at
            lower minimum investments
          </Trans>
        </li>
        <li>
          <Trans>
            Our 360° alternative solution reduces administrative and operational
            burdens
          </Trans>
        </li>
      </ul>
    ),
    path: "/distributors/alternatives",
    modalPosition: { left: "1%", top: "8rem" },
    circlePosition: { right: "2rem", bottom: "-1rem" },
    linkPosition: {
      height: "2px",
      transform: "translate(-70px, -78px)",
      before: "rotate(45deg) translate(-85%, 50px)",
    },
    gridColumn: "3/4",
    gridRow: "4/5",
    withoutRequestDemo: true,
  },
  {
    title: <Trans>Fund Distribution and Dealing</Trans>,
    description: (
      <Trans>Accurate, secure administrative and operational services.</Trans>
    ),
    color: colors.yellow,
    icon: faChartNetwork,
    content: (
      <ul>
        <li>
          <Trans>Order management, efficient execution</Trans>
        </li>
        <li>
          <Trans>Settlements</Trans>
        </li>
        <li>
          <Trans>Holdings valuation</Trans>
        </li>
        <li>
          <Trans>
            Asset servicing: stock transfers and corporate actions processing
          </Trans>
        </li>
        <li>
          <Trans>Rebate calculation and reporting</Trans>
        </li>
      </ul>
    ),
    modalPosition: { left: 0, top: "15rem" },
    circlePosition: { top: "-1rem", right: "7%" },
    linkPosition: {
      transform: "rotate(-90deg)",
      before: "rotate(-45deg) translate(-85%, -50px)",
    },
    gridColumn: "1/2",
    gridRow: "2/3",
  },
];

function DistributorsPage() {
  const [openModal, setOpenModal] = React.useState(false);
  const interestedItems = [
    { ..._.get(restHeaders(), "fund_houses") },
    { ..._.get(additionalLinks(), "investment_solutions") },
    { ..._.get(additionalLinks(), "blockchain") },
    { ..._.get(headers(), "esg.elements.approach_esg", {}) },
    { ..._.get(restHeaders(), "about.elements.about_team", {}) },
    { ..._.get(headers(), "media", {}) },
    { ..._.get(additionalLinks(), "investors_relations", {}) },
    { ..._.get(restHeaders(), "about.elements.overview", {}) },
  ];

  return (
    <>
      <NewLayout apps="distributors">
        <Hero
          kind={<Trans>Distributors</Trans>}
          title={<Trans>Your distribution partner for over 20 years</Trans>}
          description={
            <Trans>
              Manage all of your investment fund requirements from a single
              platform
            </Trans>
          }
          backgroundImage={backgroundImage}
          buttonText={<Trans>Contact us</Trans>}
          buttonPath="/contact"
        />
        <Container py={8}>
          <StyledGrid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h3">
                <Trans>Our best in class Distributor solutions</Trans>
              </Typography>
              <ArrowButton
                className="desktop-button"
                title={<Trans>Request Demo</Trans>}
                onClick={() => setOpenModal(true)}
                isDark
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography mb={1}>
                <Trans>
                  Since 2000, Allfunds has provided Distributors access to the
                  largest fund marketplace via its dealing and trading platform,
                  a fundamental service then and today. Since then we have grown
                  in size but most importantly in scope and experience.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  By actively listening to our clients' needs and anticipating
                  industry developments we have continued to expand the service
                  offering available on the Allfunds platform to deliver the
                  most comprehensive one-stop shop in the industry. Through a
                  robust offering of data analytics, ESG and customized IT
                  solutions we help our clients strengthen their own product
                  offerings, improve their stakeholder relationships, and
                  simplify fund management.
                </Trans>
              </Typography>
              <ArrowButton
                className="mobile-button"
                title={<Trans>Request Demo</Trans>}
                onClick={() => setOpenModal(true)}
                isDark
              />
            </Grid>
          </StyledGrid>
          <Box sx={{ mt: { xs: 5, sm: 10 } }}>
            <QuarterlyFigures kind="distributors" />
          </Box>
        </Container>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            py: { xs: 40, sm: 17 },
          }}
        />
        <Box sx={{ mt: { xs: -80, sm: -31 } }}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="white" variant="h3">
                  <Trans>Our value proposition for Distributors</Trans>
                </Typography>
              </Grid>
              {benefits().map(({ title, description }, index) => (
                <Grid item xs={12} sm={6}>
                  <Box
                    padding={2}
                    sx={{
                      backgroundColor: "white",
                      height: "100%",
                      borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.305882)",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Box display="flex" alignItems="baseline">
                        <StyledNumberIcon>{index + 1}.</StyledNumberIcon>
                      </Box>
                      <Typography ml={1} variant="h3">
                        {title}
                      </Typography>
                    </Box>
                    <Typography>{description}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <Container pt={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h3">
                  <Trans>Our Unique Offering</Trans>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography>
                  <Trans>
                    A set of intuitive, user-friendly services and solutions to
                    help you grow your business, strengthen client
                    relationships, and simplify your operations.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
            <RotatedGridServices items={digitalTools()} />
          </Container>
        </Box>
        <Box sx={{ mt: { xs: 3, sm: 5 } }}>
          <LatestUpdates
            title={<Trans>Latest Insights for Distributors</Trans>}
          />
        </Box>
        <Container pb={8}>
          <InterestedItems isSmall items={interestedItems} />
        </Container>
      </NewLayout>
      {openModal && (
        <ModalRequestDemo
          onClose={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
}

const StyledGrid = styled(Grid)(
  ({ theme }) => `
  ${theme.breakpoints.down("sm")} {
    .mobile-button {
      display: block;
      margin-top: 2rem;
    }
    .desktop-button {
      display: none;
    }
  }
  ${theme.breakpoints.up("sm")} {
    .mobile-button {
      display: none;
    }
    .desktop-button {
      display: block;
      margin-top: 1rem;
    }
  }
`,
);

const StyledNumberIcon = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.secondary.main}`};
  font-size: 3rem;
  font-weight: 600;
`;

export default DistributorsPage;
